.fab {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9;
  transition: 0.4s;
}

.fabSuccess {
  background-color: green !important;
  color: white !important;
  transform: scale(3.5);
}

.panel {
  background: white;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  padding: 1.5rem 1.5rem 3rem 1.5rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 30rem;
  max-width: 100vw;
  min-height: 15rem;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 110%);
  transition: 0.35s transform;
  z-index: 9;
}

.panelOpen {
  transform: translate(-50%, 0);
}

.educatorPoints :global(.mdc-list-item__secondary-text) {
  white-space: normal;
}

.houseList :global(.mdc-list-item__graphic) {
  width: 3rem;
  height: 3rem;
  font-size: 2.25rem;
  margin-right: 1rem;
  margin-left: -0.5rem;
  text-align: center;
  line-height: 3rem;
}

.list {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.shim {
  background-color: black;
  opacity: 0.3;
  transition: 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
}

.shimOut {
  pointer-events: none;
  opacity: 0;
}

.panelInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panelTextField {
  width: 100%;
  margin-top: 1rem;
}

.panelContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
  transform: translateY(0);
  transition: 0.3s;
  z-index: 4;
}

.panelContainer.panelContainerOut {
  transform: translateY(100%);
  opacity: 0;
}

.listItem {
  height: auto !important;
}

.listItem :global(.mdc-list-item__secondary-text) {
  margin-bottom: 1rem;
}

.listHeader {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: -0.25rem;
}
