@keyframes homeCardAnimateIn {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.logo {
  height: 2rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  left: -0.5rem;
}

.logo img {
  height: 100%;
  flex: 1 0 auto;
  transform: translateZ(0);
}

.cards {
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.card {
  animation: homeCardAnimateIn 1 0.5s both;
  text-align: center;
}

.card :global(.mdc-card__media):after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: radial-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2));
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 1.5rem 1rem;
}

.feedbackBtn {
  border-radius: 999px !important;
  background: #111 !important;
}

.banner {
  padding: 3rem 1.5rem;
  line-height: 1.25;
  text-align: center;
  background: var(--mdc-theme-secondary) url('~@common/assets/bg.jpg') scroll
    no-repeat center center;
  background-size: cover;
  color: var(--mdc-theme-on-secondary);
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 8rem;
}

.banner p {
  max-width: 35rem;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.bannerButton {
  margin-top: 1.5rem;
}

@media (min-width: 48rem) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 90rem) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
