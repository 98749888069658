.classroomPicker {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.classroomPickerCaret {
  position: absolute;
  right: -2rem;
  top: 0.33rem;
  color: var(--mdc-theme-primary);
}

.view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 0;
}
