.svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  animation: checkmark-dash 1s ease-in-out;
}

.path.check {
  stroke-dashoffset: -100;
  animation: checkmark-dash-check 1.5s 0.35s ease-in-out forwards;
}

@-webkit-keyframes checkmark-dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes checkmark-dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
