.drawerHeader {
  box-sizing: border-box !important ;
  min-height: 10rem !important ;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 1rem !important;
  margin-bottom: 0.5rem;
  background: var(--mdc-theme-secondary) url('~@common/assets/bg.jpg') scroll
    no-repeat 10% 30%;
  background-size: 175%;
}

.drawer {
  border-right: 0px !important;
}

.backButton {
  --mdc-theme-primary: var(--mdc-theme-on-primary, white);
  position: absolute;
  top: 1rem;
  left: 1rem;
}
