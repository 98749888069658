.view {
  padding: 1rem;
  background: white;
  flex: 1;
}

.title {
  margin: 0 0 0.25rem 0;
}

.title > h1 {
  font-weight: 800;
  margin: 0;
}
