@keyframes houseAnimateIn {
  from {
    opacity: 0;
    transform: translateX(20%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.leaderboardMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.grid {
  flex: 1;
  display: grid;
  padding-top: 1rem;
  padding-bottom: 1rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

/************************************************************
 * house
 ************************************************************/
.house {
  position: relative;
  color: white;
  display: flex;
  flex: 1;
  animation: houseAnimateIn 1 0.5s both;
}

.house:nth-child(2) {
  animation-delay: 0.05s;
}

.house:nth-child(3) {
  animation-delay: 0.1s;
}

.house:nth-child(4) {
  animation-delay: 0.15s;
}

.house:nth-child(5) {
  animation-delay: 0.2s;
}

.house:nth-child(6) {
  animation-delay: 0.25s;
}

.house:nth-child(7) {
  animation-delay: 0.3s;
}

.house:nth-child(8) {
  animation-delay: 0.35s;
}

.houseText {
  flex: 1;
  padding-right: 2rem;
  text-align: right;
}

.houseName,
.housePoints {
  margin: 0;
}

.housePoints {
  font-weight: 700;
}

.houseName {
  margin-top: 0.25rem;
}

.houseImg {
  flex: 0.6;
  height: calc(100% - 2.5rem);
  background: transparent none no-repeat right center / contain;
  margin-left: 1rem;
}

.houseBg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: houseEnterAnimation 1 1s both;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.15),
    0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  background-blend-mode: overlay;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}

.houseInner {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
