@keyframes feedItemIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 0 !important;
}

.notificationNumber {
  font-weight: 800;
  font-family: sans-serif;
}

/************************************************************
 * Winners
 ************************************************************/
.winner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3rem;
  text-align: center;
}

.winner img {
  max-width: 100%;
}

/************************************************************
 * Feed Items
 ************************************************************/
.feedItem {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  animation: feedItemIn 0.3s 1;
  position: relative;
}

.feedItemValue {
  font-weight: 800;
  padding-left: 1rem;
}

.feedItemTitle {
  font-weight: 600;
}

.feedItemLabel {
  padding: 1.25rem 1rem;
}

.feedTimestamp {
  position: absolute;
  font-size: 9px;
  opacity: 0.7;
  top: 0.5rem;
  right: 0.75rem;
}
