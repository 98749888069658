:root {
  --mdc-theme-primary: #fd5354;
  --mdc-theme-secondary: #384161;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #000;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: #fff;
  --mdc-theme-text-secondary-on-dark: hsla(0, 0%, 100%, 0.7);
  --mdc-theme-text-hint-on-dark: hsla(0, 0%, 100%, 0.5);
  --mdc-theme-text-disabled-on-dark: hsla(0, 0%, 100%, 0.5);
  --mdc-theme-text-icon-on-dark: hsla(0, 0%, 100%, 0.5);
}

body,
html {
  min-height: 100vh;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  z-index: 0;
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th {
  text-align: left;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  padding: 10px;
  line-height: 1.5;
}
table > thead > tr > th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  font-weight: 700;
}
table > tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
}
table > tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-card {
  border-radius: 0.625rem;
}

html {
  background: white;
}

#root {
  background: white;
  min-width: 100vw;
}

/** MDC FIXES */
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: var(--mdc-theme-primary);
}

.mdc-select:not(.mdc-select--disabled)
  .mdc-select__native-control:focus
  ~ .mdc-floating-label {
  color: var(--mdc-theme-primary);
}

.mdc-text-field--textarea {
  border: none !important;
}

.mdc-notched-outline,
.mdc-notched-outline__idle {
  pointer-events: none;
}

.mdc-drawer .mdc-list-item--activated,
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-menu__items .mdc-list-item {
  white-space: nowrap !important;
}

/************************************
 * Top App Bar Fixes
 ************************************/
.mdc-top-app-bar {
  background: var(--mdc-theme-surface);
  color: var(--mdc-theme-on-surface);
}

.mdc-top-app-bar__title {
  padding: 0;
  position: absolute;
  text-align: center;
  left: 4rem;
  right: 4rem;
  top: calc(50% - 1rem);
  font-weight: 800;
  font-size: 1.125rem;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: var(--mdc-theme-primary);
}

.mdc-top-app-bar .mdc-tab,
.mdc-top-app-bar .mdc-tab-bar,
.mdc-top-app-bar .mdc-tab-scroller,
.mdc-top-app-bar .mdc-tab-scroller__scroll-area--scroll,
.mdc-top-app-bar .mdc-tab-scroller__scroll-content {
  height: 100%;
}

.mdc-top-app-bar .mdc-tab-bar {
  height: 56px;
}

.mdc-top-app-bar .mdc-tab-bar {
  --mdc-theme-primary: inherit;
  --mdc-theme-on-surface: inherit;
}

@media (min-width: 37.5rem) {
  .mdc-top-app-bar .mdc-tab-bar {
    height: 64px;
  }
}

/************************************
 * Select Fixes
 ************************************/
.mdc-select__anchor {
  width: 100%;
}

.mdc-button,
.mdc-button .mdc-button__ripple {
  border-radius: 9999px;
}

/************************************
 * Typography
 ************************************/
.mdc-typography,
[class*='mdc-']:not(.rmwc-icon) {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.mdc-typography--headline5,
.mdc-typography--headline6 {
  font-weight: 800;
}

/************************************
 * Snackbar
 ************************************/

.mdc-snackbar__surface {
  border-radius: 0.75rem;
  background-color: var(--mdc-theme-secondary);
}

.mdc-snackbar__surface > .rmwc-icon:first-child {
  font-size: 2.25rem;
  margin-left: 1.75rem !important;
}

.mdc-snackbar__label {
  padding: 1.5rem;
}

.mdc-drawer__title {
  font-weight: 800;
  font-size: 1.5rem;
}

/************************************
 * List Items
 ************************************/
.mdc-list-item {
  border-radius: 1rem !important;
}

.mdc-list-item__primary-text {
  font-weight: 600;
}

/************************************
 * Buttons
 ************************************/
.mdc-button__label {
  font-weight: 600;
}

/************************************
 * Ripples
 ************************************/
.mdc-icon-button.mdc-ripple-upgraded:before,
.mdc-icon-button.mdc-ripple-upgraded:after {
  background-color: currentColor !important;
}
