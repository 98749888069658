@keyframes wheelAnimateIn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

.wheelMain {
  height: 100vh;
  width: 100vw;
}

.wheel {
  border-radius: 50%;
  width: 85vmin;
  height: 85vmin;
  background: black;
  position: fixed;
  top: calc(50% - 42.5vmin);
  left: calc(50% - 42.5vmin);
  overflow: hidden;
  border: 8px solid white;
  box-sizing: border-box;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
  cursor: grab;
  animation: wheelAnimateIn 1 0.7s;
}

.wheelInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wheelCenter {
  background-color: white;
  position: absolute;
  top: calc(50% - 6vmin);
  left: calc(50% - 6vmin);
  width: 12vmin;
  height: 12vmin;
  font-size: 12vmin !important;
  color: var(--mdc-theme-primary);
  border-radius: 50%;
}

.wheelSlice {
  background-color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 85vmin;
  height: 85vmin;
  transform-origin: top left;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  border: 4px solid white;
  pointer-events: none;
}

.wheelSlice:before {
  content: '';
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.wheelSlice img {
  transition: transform 0.3s;
  position: relative;
  top: 2%;
  left: 2%;
  width: 35%;
  position: relative;
  transform: scale(1) rotate(-45deg);
}

.wheelSliceActive:before {
  opacity: 0.6;
}

.arrow {
  color: var(--mdc-theme-primary);
  position: absolute;
  bottom: calc(50% - 56.5vmin);
  left: 50%;
  font-size: 14vmin !important;
  transform: translate(-50%, -50%) rotate(180deg);
}

.topAppBar {
  background: transparent !important;
  box-shadow: none !important;
}

.headline {
  position: fixed;
  bottom: 3rem;
  left: 0;
  right: 0;
  padding: 2rem;
  text-align: center;
}
